import React, { useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import moment from "moment";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import { Tooltip, Tag } from "antd";

import Table from "components/common/table";
import OnlineFilters from "../../online/settled/filters.component";
import RetailFilters from "../../retail/settled/filters.component";
import ColumnsButton from "components/common/columnsButton";
import ExportButton from "components/common/exportButton";
import ChangeState from "./changeState";

import { DATE_TIME_FORMAT, DATE_FORMAT, TIME_FORMAT } from "constants/date.constants";

import { BETSLIP_MODES, BET_STATE, CANCEL_USER_TYPE, KEYS_OF_LOCAL_STORAGE, BET_STATE_COLOR } from "constants/bet.constants";
import { SCHEDULED_GAME_TYPE, GAME_EVENT_TYPE, SCHEDULED_GAME_TYPE_MAPPER, GAME_CATEGORY, GAME_TYPES } from "constants/game.constants";
import { PROJECT_TYPE } from "constants/common.constants";
import { PERMISSION_ACTION } from "constants/permissions.constants";
import { FILTER_SHOW_IN_CURRENCY, FILTER_BET_STATE } from "constants/filter.constants";
import { BONUS_TYPE_TRANSLATION_TEXTS } from "constants/bonus.constants";
import { PAYMENT_TYPE_TEXT } from "constants/partner.constants";

import { makeBetText } from "utils/markets";
import { hasPermission } from "utils/permissions";
import { getPartnerCurrency } from "utils/currency";
import { isRacingGame, isLeagueGame, isCupGame, getRoundNameOfCupGame, flagsToBinary, getGamesFromConfig } from "utils/common";
import { columnsTitlesByProjectType, getIncludedColumnsFromLocalStorage, setIncludedColumnsFromLocalStorage, getFilteredColumns, convertAmt } from "utils/betslips";
import betType from "types/bet/bet.type";
import sortingType from "types/common/sorting.type";
import currencyType from "types/common/currency.type";

/** Settled Betslips Table Component */
const SettledBetsTableComponent = ({ getSettledBets, setSettledBetsSorting, setSettledBetsFilters, bets, isLoading, sorting, filters, total, globalPartnerId, projectType, exportURL, currency, currencies, filtersComponent, permission, gameCategory }) => {
	const { t } = useTranslation();

	/** Columns list, which can be included/excluded */
	const columnsToInclude = [
		...columnsTitlesByProjectType(projectType, "key"),
		{ title: t("pages.dashboard.bets.ip"), key: "ipAddress" },
		{ title: t("pages.dashboard.bets.game_type"), key: "gameType" },
		{ title: t("pages.dashboard.bets.starts_at"), key: "eventStartDate" },
		{ title: t("pages.dashboard.bets.ends_at"), key: "eventFinishTime" },
		{ title: t("pages.dashboard.bets.paidout_at"), key: "payoutTime" },
		{ title: t("pages.dashboard.bets.calculated_at"), key: "calculateTime" },
		{ title: t("pages.dashboard.bets.bonus_id"), key: "bonusId" },
		{ title: t("pages.dashboard.bets.bonus_type"), key: "bonusType" },
		{ title: t("pages.dashboard.bets.market_id"), key: "marketId" },
		...(gameCategory === GAME_CATEGORY.INSTANT
			? []
			: [
				{ title: t("pages.dashboard.bets.betting_period_start"), key: "bettingPeriodStartTime" },
				{ title: t("pages.dashboard.bets.betting_period_end"), key: "bettingPeriodEndTime" }
			]),
		...(
			projectType === PROJECT_TYPE.ONLINE && gameCategory === GAME_CATEGORY.SCHEDULED
				? [
					{ title: t("pages.dashboard.bets.promotionPercent"), key: "promotionPercent" },
					{ title: t("pages.dashboard.bets.promotionAmount"), key: "promotionAmount" }
				]
				: []
		)
	];

	const [includedColumns, setIncludedColumns] = useState(() => getIncludedColumnsFromLocalStorage(KEYS_OF_LOCAL_STORAGE.SETTLED_BETSLIPS_SAVED_KEY));

	const [showStateChangePopup, setShowStateChangePopup] = useState(null);

	const exportFilters = {
		...filters,
		status: filters.status.length === 1 && filters.status[0] === FILTER_BET_STATE.ALL ? null : flagsToBinary(filters.status)
	};

	/** Function which will fire on columns popup apply button click
	 * @function
	 * @param {array} selectedColumns - selected columns
	 * @memberOf SettledBetsTableComponent
	 */
	const handleColumnsApply = (selectedColumns) => {
		setIncludedColumns(selectedColumns);
	};

	const renderBetsStatus = (value, record) => {
		return (
			<Tag color={BET_STATE_COLOR[value]}>
				<Fragment>
					{t("pages.dashboard.bets.bet_status_" + value)}
					{hasPermission({ resource: permission, action: PERMISSION_ACTION.MODIFY }) && projectType === PROJECT_TYPE.RETAIL && record.allowEdit ? (
						<i
							className="icon-edit"
							onClick={() => {
								return handleStateChangeClick({
									id: record.id,
									status: value,
									allowOnlyCancel: record.bets.some((b) => b.gameType === SCHEDULED_GAME_TYPE.KENO.value)
								});
							}}
						/>
					) : null}
				</Fragment>
			</Tag>
		);
	};

	const renderBetsStatusOfDetails = (value, record, settled) => {
		return (
			<Tag color={BET_STATE_COLOR[value]}>
				<Fragment>
					{t("pages.dashboard.bets.bet_status_" + value)}
					{hasPermission({ resource: permission, action: PERMISSION_ACTION.MODIFY }) &&
						projectType === PROJECT_TYPE.RETAIL &&
						!record.payoutTime &&
						record.type !== BETSLIP_MODES.MULTI &&
						value !== BET_STATE.REJECTED_BY_OPERATOR &&
						(value !== BET_STATE.CANCELLED || settled.gameType !== SCHEDULED_GAME_TYPE.KENO.value) &&
						record.bonusId === null &&
						settled.allowEdit ? (
						<i
							className="icon-edit"
							onClick={() => {
								return handleStateChangeClick({
									betId: settled.id,
									id: record.id,
									status: settled.status,
									allowOnlyCancel: settled.gameType === SCHEDULED_GAME_TYPE.KENO.value
								});
							}}
						/>
					) : null}
				</Fragment>
			</Tag>
		);
	};

	/** Columns of table */
	const columns = [
		{
			title: t("pages.dashboard.bets.id"),
			dataIndex: "id"
		},
		{
			title: t("pages.dashboard.bets.date"),
			dataIndex: "betTime",
			render: (value) => moment.utc(value).local().format(DATE_TIME_FORMAT)
		},
		...(gameCategory === GAME_CATEGORY.INSTANT
			? []
			: [
				{
					title: t("pages.dashboard.bets.bet_type"),
					dataIndex: "type",
					render: (value) => {
						switch (value) {
							case BETSLIP_MODES.SINGLE:
								return t("pages.dashboard.bets.single");
							case BETSLIP_MODES.MULTI:
								return t("pages.dashboard.bets.multi");
							default:
								break;
						}
						return "";
					}
				}
			]),
		{
			title: t("pages.dashboard.bets.status"),
			dataIndex: "status",
			className: "ant-table-tag-cell",
			render: (value, record) =>
				record.cancelUserType !== CANCEL_USER_TYPE.NONE || value === BET_STATE.REJECTED_BY_OPERATOR ? (
					<Tooltip
						title={
							<Fragment>
								{record.cancelUserType !== CANCEL_USER_TYPE.NONE && (
									<Fragment>
										<b>{t("pages.dashboard.bets.cancel_user_type_" + record.cancelUserType)}</b>
										<br />
									</Fragment>
								)}

								{record.reason && <span>{`${t("pages.dashboard.bets.reason")}: ${record.reason}`}</span>}
							</Fragment>
						}
					>
						{renderBetsStatus(value, record)}
					</Tooltip>
				) : (
					renderBetsStatus(value, record)
				)
		},
		{
			title: projectType === PROJECT_TYPE.ONLINE ? t("pages.dashboard.bets.project") : projectType === PROJECT_TYPE.RETAIL ? t("pages.dashboard.bets.betshop") : " - ",
			dataIndex: "projectName"
		},
		...(projectType === PROJECT_TYPE.ONLINE
			? [
				{
					title: t("pages.dashboard.games.gameCategory"),
					dataIndex: "gameCategory",
					render: value => t(`pages.dashboard.games.gameCategory_${value}`),
					sorter: false
				}
			]
			: []),
		{
			title: t("pages.dashboard.bets.paidout_at"),
			dataIndex: "payoutTime",
			render: (value) => (value ? moment.utc(value).local().format(DATE_TIME_FORMAT) : "-"),
			sorter: false
		},
		...(projectType === PROJECT_TYPE.ONLINE ? columnsTitlesByProjectType(projectType, "dataIndex") : []),
		{
			title: projectType === PROJECT_TYPE.ONLINE ? t("pages.dashboard.bets.username") : projectType === PROJECT_TYPE.RETAIL ? t("pages.dashboard.bets.cashier") : " - ",
			dataIndex: "userName",
			render: (value, record) => {
				if (projectType === PROJECT_TYPE.ONLINE && record.isTesting) {
					return value + "(Test)";
				}
				return value;
			}
		},
		{
			title: t("pages.dashboard.bets.currency"),
			dataIndex: "currencyId",
			render: (value, record) => {
				let currencyCode = currency.code;

				if (filters.showIn === FILTER_SHOW_IN_CURRENCY.ORIGINAL || value === "FUN") {
					currencyCode = value;
				}

				return getPartnerCurrency(currencyCode, currencies, globalPartnerId, record.betTime);
			}
		},
		{
			title: t("pages.dashboard.bets.odds"),
			dataIndex: "factor",
			render: (value, record) => (record.type === BETSLIP_MODES.MULTI ? Number(value).toFixed(2) : "-"),
			sorter: false
		},
		...(
			projectType === PROJECT_TYPE.ONLINE && gameCategory === GAME_CATEGORY.SCHEDULED
				? [
					{
						title: t("pages.dashboard.bets.promotionPercent"),
						dataIndex: "promotionPercent",
						render: (value, record) => {
							return (
								<div>
									{
										record.type === BETSLIP_MODES.MULTI && !isNullish(value)
											? value
											: "-"
									}
								</div>
							);
						}
					},
					{
						title: t("pages.dashboard.bets.promotionAmount"),
						dataIndex: "promotionAmount",
						render: (_, record) => {
							const value = (record.status === BET_STATE.LOST) ? record.possiblePromotionAmount : record.promotionAmount
							return (
								<div>
									{
										record.type === BETSLIP_MODES.MULTI && !isNullish(value)
											? convertAmt(value, record, currencies, currency, globalPartnerId, filters)
											: "-"
									}
								</div>
							);
						}
					}
				]
				: []
		),
		{
			title: t("pages.dashboard.bets.stake"),
			dataIndex: "amount",
			render: (value, record) => convertAmt(value, record, currencies, currency, globalPartnerId, filters)
		},
		{
			title: t("pages.dashboard.bets.possible_win"),
			dataIndex: "possibleWin",
			render: (value, record) => convertAmt(value, record, currencies, currency, globalPartnerId, filters)
		},
		{
			title: t("pages.dashboard.bets.win"),
			dataIndex: "winning",
			render: (value, record) => {
				const winning = convertAmt(value, record, currencies, currency, globalPartnerId, filters);
				if (!record.bonusId) {
					return winning;
				}
				return <span title={`${t("pages.dashboard.bets.win")}:${winning}`}>{winning}</span>;
			}
		},
		{
			title: t("pages.dashboard.bets.redeem"),
			dataIndex: "redeem",
			render: (value, record) => {
				const redeem = convertAmt(value, record, currencies, currency, globalPartnerId, filters);
				if (!record.bonusId) {
					return redeem;
				}
				const redeemText = record.redeem === null ? " - " : redeem;
				return <span title={`${t("pages.dashboard.bets.redeem")}:${redeemText}`}>{redeemText}</span>;
			}
		},
		{
			title: t("pages.dashboard.bets.calculated_at"),
			dataIndex: "calculateTime",
			render: (value) => (value ? moment.utc(value).local().format(DATE_TIME_FORMAT) : "-"),
			sorter: false
		},
		{
			title: t("pages.dashboard.bets.bonus_id"),
			dataIndex: "bonusId",
			sorter: false,
			render: (value, record) => {
				if (record.bonusId === null) {
					return " - ";
				}
				return <span title={record.bonusId}>{record.bonusId}</span>;
			}
		},
		{
			title: t("pages.dashboard.bets.bonus_type"),
			dataIndex: "bonusType",
			render: (value, record) => {
				let bonusTypeText = " - ";
				if (record.bonusId === null) {
					return bonusTypeText;
				}

				bonusTypeText = BONUS_TYPE_TRANSLATION_TEXTS[record.bonusType] ? t(BONUS_TYPE_TRANSLATION_TEXTS[record.bonusType]) : value;

				return <span title={bonusTypeText}>{bonusTypeText}</span>;
			}
		},
		{
			title: t("pages.dashboard.bets.ip"),
			dataIndex: "ipAddress"
		},
		...(projectType === PROJECT_TYPE.RETAIL
			? [
				{
					title: t("pages.dashboard.partners.retail_settings.retail_configs.payment_type"),
					dataIndex: "paymentType",
					render: (value) => t(PAYMENT_TYPE_TEXT[value])
				}
			]
			: [])
	];

	/** Function to get table details data
	 * @function
	 * @param {object} record - row item
	 * @returns {object} - table details data
	 * @memberOf SettledBetsTableComponent
	 */
	const getDetailsData = (record) => {
		if (!record.bets) {
			return null;
		}

		const columns = [
			{
				title: t("pages.dashboard.bets.bet_id"),
				dataIndex: "id"
			},
			{
				title: t("pages.dashboard.bets.game_type"),
				dataIndex: "gameType",
				render: (value) =>
					t(
						`common.${getGamesFromConfig(gameCategory)
							.filter((v) => v.value === value)
							.map((v) => v.type)}`
					)
			},
			{
				title: t("pages.dashboard.bets.game"),
				dataIndex: "gameName"
			},
			{
				title: t("pages.dashboard.bets.status"),
				render: (value, settled) => (settled.reason ? <Tooltip title={settled.reason}>{renderBetsStatusOfDetails(value, record, settled)}</Tooltip> : renderBetsStatusOfDetails(value, record, settled)),
				dataIndex: "status"
			},
			{
				title: t("pages.dashboard.bets.event_id"),
				dataIndex: "eventId"
			},
			{
				title: t("pages.dashboard.bets.event"),
				dataIndex: "gameData",
				render: (value, settled) => {
					if (!settled.eventId) {
						return (
							<span className="info-text error-text">
								{t("common.event_not_found")}
							</span>
						);
					}
					if ([GAME_TYPES[gameCategory].FOOTBALL_SINGLE_MATCH.value, SCHEDULED_GAME_TYPE.PENALTY_SHOOTOUT.value].includes(settled.gameType)) {
						return `${settled?.gameData?.team1?.countryName ?? ""} - ${settled?.gameData?.team2?.countryName ?? ""}`;
					}

					if (isLeagueGame(settled.gameType)) {
						const getLeagueGamesEventTitle = () => {
							if (settled.gameType === SCHEDULED_GAME_TYPE.ENGLISH_LEAGUE.value) {
								return t("common.english_league");
							}
							if (settled.gameType === SCHEDULED_GAME_TYPE.TURKISH_LEAGUE.value) {
								return t("common.turkish_league");
							}
						}

						if (settled.eventType === GAME_EVENT_TYPE.LEAGUE) {
							return getLeagueGamesEventTitle();
						}
						if (settled.gameData) {
							return `${settled?.gameData?.team1?.countryCode ?? ""} - ${settled?.gameData?.team2?.countryCode ?? ""}`;
						}
						return getLeagueGamesEventTitle();
					}

					if (isCupGame(settled.gameType)) {
						if (settled.eventType === GAME_EVENT_TYPE.LEAGUE) {
							return t(`common.${SCHEDULED_GAME_TYPE_MAPPER[settled.gameType]}`);
						}
						if (settled.eventType === GAME_EVENT_TYPE.WEEK) {
							return getRoundNameOfCupGame(settled.eventOrderNumber, settled.gameType);
						}
						if (settled.gameData) {
							return `${settled?.gameData?.team1?.countryCode ?? ""} - ${settled?.gameData?.team2?.countryCode ?? ""}`;
						}

						return t(`common.${SCHEDULED_GAME_TYPE_MAPPER[settled.gameType]}`);

					}

					if (isRacingGame(settled.gameType)) {
						return settled?.gameData?.venue ?? ""
					}

					if (settled.gameType === SCHEDULED_GAME_TYPE.KENO.value) {
						return t("common.keno");
					}
					return "";
				}
			},
			{
				title: t("pages.dashboard.bets.result"),
				dataIndex: "result",
				render: (value, settled) => {
					return renderResult(settled);
				}
			},
			{
				title: t("pages.dashboard.bets.starts_at"),
				dataIndex: "eventStartDate",
				render: (value) => moment.utc(value).local().format(DATE_TIME_FORMAT)
			},
			{
				title: t("pages.dashboard.bets.ends_at"),
				dataIndex: "eventFinishTime",
				render: (value) => {
					if (value) {
						return moment.utc(value).local().format(DATE_TIME_FORMAT);
					}
					return "";
				}
			},
			...(gameCategory !== GAME_CATEGORY.INSTANT)
				? [
						{
							title: t("pages.dashboard.bets.betting_period_start"),
							dataIndex: "bettingPeriodStartTime",
							render: (value) => {
								if (value) {
									return moment.utc(value).local().format(DATE_TIME_FORMAT);
								}
								return "";
							}
						},
						{
							title: t("pages.dashboard.bets.betting_period_end"),
							dataIndex: "bettingPeriodEndTime",
							render: (value) => {
								if (value) {
									return moment.utc(value).local().format(DATE_TIME_FORMAT);
								}
								return "";
							}
						},
					]
				: [],
			{
				title: t("pages.dashboard.bets.market_id"),
				dataIndex: "marketId",
				render: (value) => value || "-"
			},
			{
				title: t("pages.dashboard.bets.market_selection"),
				dataIndex: "odd",
				render: (value, settled) =>
					makeBetText({
						period: settled.period,
						argument: settled.argument,
						outcome: value,
						group: settled.market,
						gameData: settled.gameData,
						gameType: settled.gameType,
						odd: settled.odd
					})
			},
			{
				title: t("pages.dashboard.bets.odds"),
				dataIndex: "factor"
			},
			{
				title: t("pages.dashboard.bets.stake"),
				dataIndex: "amount",
				render: (value) => convertAmt(value, record, currencies, currency, globalPartnerId, filters)
			},
			{
				title: t("pages.dashboard.bets.possible_win"),
				dataIndex: "possibleWin",
				render: (value) => convertAmt(value, record, currencies, currency, globalPartnerId, filters)
			},
			{
				title: t("pages.dashboard.bets.win"),
				render: (value, settled) => {
					const winning = convertAmt(value, record, currencies, currency, globalPartnerId, filters);
					if (!record.bonusId) {
						return winning;
					}
					return <span title={`${t("pages.dashboard.bets.win")}:${winning}`}>{winning}</span>;
				},
				dataIndex: "winning"
			},
			{
				title: t("pages.dashboard.bets.redeem"),
				render: (value, settled) => {
					if (!record.bonusId) {
						return convertAmt(value, record, currencies, currency, globalPartnerId, filters);
					} else {
						const redeem = record.redeem === null ? " - " : convertAmt(settled.redeem, record, currencies, currency, globalPartnerId, filters);
						return <span title={`${t("pages.dashboard.bets.redeem")}:${redeem}`}>{redeem}</span>;
					}
				},
				dataIndex: "redeem"
			}
		];

		const filteredColumns = getFilteredColumns(columns, columnsToInclude, includedColumns);

		return {
			columns: filteredColumns,
			data: record.bets,
			uniqueKey: "id"
		};
	};

	/** Function to render event result
	 * @function
	 * @param {object} settled
	 * @returns {JSX}
	 * @memberOf SettledBetsTableComponent
	 */
	const renderResult = (settled) => {
		if (settled.eventType === GAME_EVENT_TYPE.WEEK) {
			return " - ";
		}

		if (!settled.gameData) {
			return <span className="info-text error-text">{t("common.event_not_found")}</span>;
		}

		if (settled.eventType === GAME_EVENT_TYPE.LEAGUE) {
			if (isLeagueGame(settled.gameType)) {
				const group = settled?.market ?? null

				const groupResult = group === "Winner" ? settled.gameData.markets.winner || undefined : group === "Loser" ? settled.gameData.markets.loser || undefined : undefined;
				if (groupResult !== undefined) {
					return groupResult;
				}
				return " - ";
			}

			if (isCupGame(settled.gameType)) {
				const group = settled?.market ?? null

				const groupResult = group === "Champion" ? settled.gameData.markets.champion || undefined : group === "Finalist" ? settled.gameData.markets.finalist || undefined : undefined;
				if (groupResult !== undefined) {
					return groupResult;
				}
				return " - ";
			}
		}

		if (isRacingGame(settled.gameType)) {
			const participants = settled?.gameData?.participants ?? [];
			const firstPlace = participants.find((p) => p.place === 1);

			return `${(firstPlace?.number ?? "")}. ${firstPlace?.name ?? ""}`;
		}

		if (settled.gameType === SCHEDULED_GAME_TYPE.KENO.value) {
			const scenes = settled?.gameData?.scenes ?? [];
			const res = scenes
				.map((sc) => sc.number)
				.join(", ");
			return (
				<span title={res} className="table-col-shorten-text">
					{res}
				</span>
			);
		}

		const team1 = settled?.gameData?.team1;
		const team2 = settled?.gameData?.team2;

		if (team1 && team2) {
			return `${(team1.goalCount ?? 0)} - ${(team2.goalCount ?? 0)}`;
		}

		return " - ";
	};

	/** Fires on state change button click
	 * @function
	 * @param {object} item - the item
	 * @memberOf SettledBetsTableComponent
	 */
	const handleStateChangeClick = (item) => {
		setShowStateChangePopup(item);
	};

	/** Keep included columns in local storage */
	useEffect(() => {
		setIncludedColumnsFromLocalStorage(KEYS_OF_LOCAL_STORAGE.SETTLED_BETSLIPS_SAVED_KEY, includedColumns);
	}, [includedColumns]);

	return (
		<div className="dashboard-section-content">
			<div className="table-header">
				<div className="table-buttons-dropdowns">
					{hasPermission({ resource: permission, action: PERMISSION_ACTION.EXPORT }) ? (
						<ExportButton columns={columns.map((c) => ({ title: c.title, key: c.dataIndex }))} tableName={t("pages.dashboard.bets.tabs.settled_betslips")} url={exportURL} filters={exportFilters} sorting={sorting} />
					) : null}
					<ColumnsButton columns={columnsToInclude} onApply={handleColumnsApply} defaultSelectedColumns={includedColumns} />
				</div>
				{filtersComponent ? (
					<Fragment>{filtersComponent}</Fragment>
				) : projectType === PROJECT_TYPE.ONLINE ? (
					<OnlineFilters projectType={projectType} gameCategory={gameCategory} />
				) : projectType === PROJECT_TYPE.RETAIL ? (
					<RetailFilters projectType={projectType} gameCategory={gameCategory} />
				) : (
					<Fragment />
				)}
			</div>
			<Table
				loading={isLoading}
				columns={getFilteredColumns(columns, columnsToInclude, includedColumns)}
				data={bets}
				loadFn={(fromFirstPage) => getSettledBets(fromFirstPage, gameCategory)}
				sorting={sorting}
				filters={filters}
				setSortingFn={setSettledBetsSorting}
				setFiltersFn={setSettledBetsFilters}
				total={total}
				updateProps={[globalPartnerId]}
				isDisabled={() => false}
				detailsType="table"
				details={getDetailsData}
				enableReload={true}
			/>
			{showStateChangePopup ? <ChangeState item={showStateChangePopup} onClose={() => setShowStateChangePopup(null)} /> : null}
		</div>
	);
};

/** SettledBetsTableComponent propTypes
 * PropTypes
 */
SettledBetsTableComponent.propTypes = {
	/** Function to get settled bets */
	getSettledBets: PropTypes.func,
	/** The array of settled bets */
	bets: PropTypes.arrayOf(betType),
	/** Is true when loading settled bets */
	isLoading: PropTypes.bool,
	/** Function to set settled bets sorting details */
	setSettledBetsSorting: PropTypes.func,
	/** Function to set settled bets filters */
	setSettledBetsFilters: PropTypes.func,
	/** Settled bets sorting details */
	sorting: sortingType,
	/** Settled bets filters */
	filters: PropTypes.object,
	/** Settled bets total count */
	total: PropTypes.number,
	/** Redux state property, represents global partner id */
	globalPartnerId: PropTypes.string,
	/** The Project Type (online/retail) */
	projectType: PropTypes.oneOf(Object.values(PROJECT_TYPE)),
	/** The url to export table data */
	exportURL: PropTypes.string,
	/** Redux state property, the current user currency */
	currency: currencyType,
	/** Redux state property, the current user currencies */
	currencies: PropTypes.arrayOf(currencyType),
	/** The JSX content of filters */
	filtersComponent: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
	/** The permission for actions */
	permission: PropTypes.string,
	/** React property, game category */
	gameCategory: PropTypes.oneOf(Object.values(GAME_CATEGORY))
};

const mapStateToProps = (state) => {
	return {
		globalPartnerId: state.partner.globalPartnerId,
		currencies: state.profile.userInfo.currencies,
		currency: state.profile.userInfo.currency
	};
};

export default connect(mapStateToProps, null)(SettledBetsTableComponent);